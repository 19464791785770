import { Routes, Route } from "react-router-dom";
import Cart from "pages/Cart";
import PageLayout from "layouts/PageLayout";
import Shop from "pages/Shop";
import NotFound from "pages/NotFound";
import Stores from "pages/Stores";
import StoreDetails from "pages/StoreDetails";
import Home from "pages/Home";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<Home />} />
        <Route path="cart" element={<Cart />} />
        <Route path="shop" element={<Shop />} />
        <Route path="stores" element={<Stores />} />
        <Route path="store/:storeId" element={<StoreDetails />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
