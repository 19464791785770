import { configureStore } from '@reduxjs/toolkit';
import cartReducer from 'features/cartSlice';
import productsReducer from 'features/productsSlice';
import sellersReducer from 'features/sellersSlice';

const store = configureStore({
  reducer: {
    cart: cartReducer,
    products: productsReducer,
    sellers: sellersReducer,
  },
});

export default store;