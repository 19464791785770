import CartHelper from 'app/CartHelper';
import ShopCart from 'components/Cart/ShopCart';
import { removeAllItemsFromCart, removeFromCart, updateCartItemCheckedState } from 'features/cartSlice';
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function Cart() {
  useDocumentTitle('Cart');

  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const [parsedCart, setParsedCart] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [countChecked, setCountChecked] = useState(0);
  
  useEffect(() => {
    const allChecked = cart.items.every(item => item.checked === true);
    setCheckedAll(allChecked);
    setCountChecked(cart.items.reduce((total, item) => (!item.checked) ? total : total + item.qty, 0));
  }, [cart]);

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    setCheckedAll(isChecked);
    cart.items.forEach(item => {
      dispatch(updateCartItemCheckedState({ id: item.id, checked: isChecked }));
    });
  };

  const handleRemoveAllItems = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You're about to empty your cart",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, empty it!"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Success!",
          text: "Your cart is now empty.",
          icon: "success"
        });
        dispatch(removeAllItemsFromCart());
      }
    });
  }

  const handleMockCheckout = (e) => {
    e.preventDefault();

    if (countChecked < 1) {
      Swal.fire({
        title: 'Whoops...',
        text: 'No item selected to checkout',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    let copyTotalPrice = cart.totalPrice,
      copyCountChecked = countChecked;

    cart.items.forEach(item => {
      if (item.checked) {
        dispatch(removeFromCart({ id: item.id }));
      }
    });

    Swal.fire({
      title: 'Thank you!',
      html: `Your order totaled <b>$${copyTotalPrice}</b> for <b>${copyCountChecked}</b> item(s).`,
      icon: 'success',
      showConfirmButton: false
    });
  }

  useEffect(() => {
    if (cart.items.length > 0) {
      const parsed = CartHelper.groupBySeller(cart.items);
      setParsedCart(parsed);
    } else {
      setParsedCart([]);
    }
  }, [cart]);

  return (
    <div className="shopping-cart">
      <div className="container">
        <div className="shopping-cart__container">
          <h1 className="shopping-cart__title">Shopping Cart</h1>
          <div className="row">
            <div className="col-lg-7 col-xl-8">
              <div className="shopping-cart__container">
                {(parsedCart.length < 1) && (
                  <div className="card d-flex align-items-center">
                    <div className="card-body d-flex align-items-center">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"
                        className="me-3"
                        style={{ width: '150px', height: 'auto' }}
                        alt="Empty cart"
                      />
                      <div style={{ width: '100%' }}>
                        <h5 className="card-title">Your cart is empty</h5>
                        <p className="card-text">Looks like you haven't added anything to your cart yet.</p>
                        <div className="d-grid">
                          <Link to="/shop" className="btn btn-dark btn-block">Go to Shop</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(parsedCart.length > 0) && (
                  <div className="card p-2 mb-2 d-flex justify-content-between align-items-center flex-row">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="checkbox"
                          className="form-check-input shop-checkbox"
                          checked={checkedAll}
                          onChange={handleCheckAll}
                        />
                        <span className="ms-2">Select All</span>
                      </label>
                    </div>
                    <div className="order-summary__action me-2">
                      <a href="#" onClick={(e) => handleRemoveAllItems(e)}>Empty cart</a>
                    </div>
                  </div>
                )}
                {parsedCart.map(cart => (
                  <ShopCart key={cart.id} details={cart} />
                ))}
              </div>
            </div>
            <div className="col-lg-5 col-xl-4">
              <div className="shopping-cart__order-summary">
                <div className="order-summary__background">
                  <h3 className="order-summary__title">Order Summary</h3>
                  <div className="order-summary__subtotal">
                    <div className="summary-subtotal__title">Subtotal</div>
                    <div className="summary-subtotal__price">${cart.totalPrice.toFixed(2)}</div>
                  </div>
                  <div className="order-summary__total">
                    <div className="summary-total__title">Total</div>
                    <div className="summary-total__price">${cart.totalPrice.toFixed(2)}</div>
                  </div>
                  <div className="order-summary__proceed-to-checkout">
                    <a href="#" onClick={(e) => handleMockCheckout(e)} className="second-button">Proceed to checkout ({countChecked})</a>
                  </div>
                  <div className="order-summary__accept-payment-methods">
                    <h4 className="accept-payment-methods__title">Accept Payment Methods</h4>
                    <img src="assets/images/default/payment.png" alt="Payment" />
                  </div>
                </div>
                <div className="order-summary__action"><Link to="/shop">Continue shopping</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cart;