import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('cart', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('cart');
  return data ? JSON.parse(data) : defaultValue;
};

const calculateTotalPrice = (items) => {
  return Number(Number(items.reduce((total, item) => {
    return item.checked ? total + item.final_price * item.qty : total;
  }, 0)).toFixed(2));
};

const initialCart = {
  items: [],
  totalPrice: 0,
};

const initialState = loadFromLocalStorage(initialCart);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { id, qty, details } = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        existingItem.qty += qty;
      } else {
        // Haiya, this is the one causing the undefined properties
        // When we add an item to cart then change the sorting method.
        // Can't just copy the object, as it'll still be referenced.
        let copyDetails = JSON.parse(JSON.stringify(details));
        copyDetails.image = copyDetails.images[0];
        delete copyDetails.images;
        state.items.push({id, qty, ...copyDetails });
      }

      state.totalPrice = calculateTotalPrice(state.items);
      updateLocalStorage(state);
    },
    removeFromCart: (state, action) => {
      const { id } = action.payload;
      state.items = state.items.filter(item => item.id !== id);

      state.totalPrice = calculateTotalPrice(state.items);
      updateLocalStorage(state);
    },
    updateCartItemQty: (state, action) => {
      const { id, qty } = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        if (qty < 0) {
          qty = 1;
        }
        existingItem.qty = qty;
      }

      state.totalPrice = calculateTotalPrice(state.items);
      updateLocalStorage(state);
    },
    updateCartItemNote: (state, action) => {
      const { id, note } = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        existingItem.note = note;
      }

      state.totalPrice = calculateTotalPrice(state.items);
      updateLocalStorage(state);
    },
    updateCartItemCheckedState: (state, action) => {
      const { id, checked } = action.payload;
      const existingItem = state.items.find(item => item.id === id);

      if (existingItem) {
        existingItem.checked = checked;
      }

      state.totalPrice = calculateTotalPrice(state.items);
      updateLocalStorage(state);
    },
    removeFromCartBySellerId: (state, action) => {
      const { sellerId } = action.payload;

      state.items = state.items.filter(item => item.shop.id !== sellerId);

      state.totalPrice = calculateTotalPrice(state.items);
      updateLocalStorage(state);
    },
    removeAllItemsFromCart: (state, action) => {
      state.items = [];
      state.totalPrice = 0;
      updateLocalStorage(state);
    }
  },
});

export const { addToCart, removeFromCart, updateCartItemQty, updateCartItemNote, updateCartItemCheckedState, removeFromCartBySellerId, removeAllItemsFromCart } = cartSlice.actions;
export default cartSlice.reducer;
