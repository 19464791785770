import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromCart } from 'features/cartSlice'

function CartDropdown() {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);

  const handleRemoveFromCart = (productId) => {
    dispatch(removeFromCart({ id: productId }));
  };

  const totalItems = cart.items.reduce((total, item) => total + item.qty, 0);
  const subtotal = cart.items.reduce((total, item) => total + (item.price * item.qty), 0);

  return (
    <li className="header__cart">
      <Link to="/cart">
        <i className="lnil lnil-cart"></i><span>{totalItems}</span>
        <p className="header-cart__price">${subtotal.toFixed(2)}</p>
      </Link>
      <div className="header-cart">
        {(!cart.items.length) ? (
          <div className="text-center">
            <img
              src="https://cdn-icons-png.flaticon.com/512/11329/11329060.png"
              className="me-3"
              style={{ width: '150px', height: 'auto' }}
              alt="Empty cart"
            />
            <div style={{ width: '100%' }}>
              <h5 className="card-title">Your cart is empty</h5>
              <p className="card-text">Looks like you haven't added anything to your cart yet.</p>
              <div className="d-grid">
                <Link to="/shop" className="btn btn-dark btn-block">Go to Shop</Link>
              </div>
            </div>
          </div>
        ) : (<>
          <ul className="header-cart__items">
            {cart.items.map(item => (
              <li key={item.id} className="cart-item d-flex me-2">
                <p className="cart-item__image">
                  <a href="#" style={{paddingBottom: "100%"}}>
                    <img alt={item.title} src={`/assets/products/${item.image}`} className="lazyload lazy-effect" />
                  </a>
                </p>
                <p className="cart-item__details">
                  <a href="#" className="cart-item__title">{item.title}</a>
                  <span className="cart-item__variant">{item.shop.name}</span>
                  <span className="cart-ietm__price">{item.quantity}<i>x</i>${item.price.toFixed(2)}</span>
                </p>
                <p className="cart-item__delete">
                  <a href="#" onClick={(e) => { e.preventDefault(); handleRemoveFromCart(item.id); }}>
                    <i className="lnil lnil-close"></i>
                  </a>
                </p>
              </li>
            ))}
          </ul>
          <div className="header-cart__subtotal d-flex">
            <div className="subtotal__title">Subtotal</div>
            <div className="subtotal__value">${subtotal.toFixed(2)}</div>
          </div>
          <div className="header-cart__action">
            <Link to="/cart" className="header-cart__button">View cart</Link>
          </div>
        </>)}
      </div>
    </li>
  )
}

export default CartDropdown