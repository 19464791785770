import React from 'react';
import { NavLink } from 'react-router-dom';
import CartDropdown from './Header/CartDropdown';

function Header() {
  return (
    <>
      <header className="header js-header">
        <div className="container container--type-2">
          <div className="header__container d-flex align-items-center">
            <div className="header__open-mobile-menu"><a href="#" className="js-open-mobile-menu"><i className="lnil lnil-menu"></i><i className="lnil lnil-close"></i></a></div>
            <h1 className="header__logo"><NavLink to="/">BLAZ STORE</NavLink></h1>
            <ul className="header__nav">
              <li>
                <NavLink to="/" className={({ isActive }) => isActive ? "nav__item nav__item--current" : "nav__item"} end>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/shop" className={({ isActive }) => isActive ? "nav__item nav__item--current" : "nav__item"}>
                  All Products
                </NavLink>
              </li>
              <li>
                <NavLink to="/stores" className={({ isActive }) => isActive ? "nav__item nav__item--current" : "nav__item"}>
                  All Stores
                </NavLink>
              </li>
            </ul>
            <ul className="header__right">
              <CartDropdown />
            </ul>
          </div>
        </div>
      </header>
      <div className="fixed-header js-fixed-header"></div>
    </>
  )
}

export default Header