import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <>
      <div className="not-found-page">
      <div className="container">
        <h1 className="not-found-page__title">Welcome</h1>
        <div className="not-found-page__description">To the greatest store in the world.</div>
        <div className="not-found-page__action"><Link to="/shop" className="button">View All Products</Link></div>
      </div>
    </div>
      <div className="features">
        <div className="container">
          <hr />
          <div className="features__d-flex js-home-features-carousel">
            <div className="feature d-flex">
              <div className="feature__icon"><i className="lnil lnil-ship"></i></div>
              <div className="feature__content">
                <h6 className="feature__title">Free Shipping</h6>
                <div className="feature__description">International shipping available<br />for all orders over $99</div>
              </div>
            </div>
            <div className="feature d-flex">
              <div className="feature__icon"><i className="lnil lnil-credit-card"></i></div>
              <div className="feature__content">
                <h6 className="feature__title">Buy Now. Pay Later</h6>
                <div className="feature__description">0% Interest financing available<br />Pay after 30 days.</div>
              </div>
            </div>
            <div className="feature d-flex">
              <div className="feature__icon"><i className="lnil lnil-reload"></i></div>
              <div className="feature__content">
                <h6 className="feature__title">Cashback Reward</h6>
                <div className="feature__description">Collect & Redeem on every order<br />that you purchased</div>
              </div>
            </div>
            <div className="feature d-flex">
              <div className="feature__icon"><i className="lnil lnil-money-protection"></i></div>
              <div className="feature__content">
                <h6 className="feature__title">Secure Payments</h6>
                <div className="feature__description">Guarantee 100% secure payments<br />online on our website</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home