const CartHelper = {
    groupBySeller(items) {
        let toReturn = {};
        items.forEach(item => {
            if (typeof toReturn[item.shop.id] == 'undefined') {
                toReturn[item.shop.id] = {
                    id: item.shop.id,
                    name: item.shop.name,
                    items: []
                };
            }
            toReturn[item.shop.id].items.push(item);
        });
        return Object.values(toReturn);
    }
};

export default CartHelper;