import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className="not-found-page">
      <div className="container">
        <h1 className="not-found-page__title">404</h1>
        <div className="not-found-page__description">This page has been probably moved somewhere.</div>
        <div className="not-found-page__action"><Link to="/" className="button">Back to homepage</Link></div>
      </div>
    </div>
  )
}

export default NotFound;