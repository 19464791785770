import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('products', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('products');
  return data ? JSON.parse(data) : defaultValue;
};

const initialProducts = {
  products: require('app/data/products.json')
};

const initialState = loadFromLocalStorage(initialProducts);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {}
});

// export const { } = productsSlice.actions;
export default productsSlice.reducer;
