import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('sellers', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('sellers');
  return data ? JSON.parse(data) : defaultValue;
};

const initialSellers = {
  sellers: require('app/data/sellers.json')
};

const initialState = loadFromLocalStorage(initialSellers);

const sellersSlice = createSlice({
  name: 'sellers',
  initialState,
  reducers: {}
});

// export const { } = sellersSlice.actions;
export default sellersSlice.reducer;
