import ShopHelper from 'app/ShopHelper'
import SellerCard from 'components/Sellers/SellerCard';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

function Stores() {
  useDocumentTitle('All Stores');

  const products = useSelector(state => state.products.products);
  const sellers = useSelector(state => state.sellers.sellers);
  const [toDisplay, setToDisplay] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMethod, setSortMethod] = useState('default');
  const storesPerPage = 6;

  useEffect(() => {
    if (products.length > 0 && sellers.length > 0) {
      const parsed = ShopHelper.getProductsGroupedBySeller(products, sellers);
      setToDisplay(parsed);
    } else {
      setToDisplay([]);
    }
  }, [products, sellers]);

  const sortStores = (stores, method) => {
    switch (method) {
      case 'nameAZ':
        return [...stores].sort((a, b) => a.name.localeCompare(b.name));
      case 'nameZA':
        return [...stores].sort((a, b) => b.name.localeCompare(a.name));
      case 'rating':
        return [...stores].sort((a, b) => b.rating - a.rating);
      case 'productCountAsc':
        return [...stores].sort((a, b) => a.items.length - b.items.length);
      case 'productCountDesc':
        return [...stores].sort((a, b) => b.items.length - a.items.length);
      default:
        return stores;
    }
  };

  const sortedStores = sortStores(toDisplay, sortMethod);

  // For pagination
  const indexOfLastStore = currentPage * storesPerPage;
  const indexOfFirstStore = indexOfLastStore - storesPerPage;
  const currentStores = sortedStores.slice(indexOfFirstStore, indexOfLastStore);
  const totalPages = Math.ceil(sortedStores.length / storesPerPage);
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSortChange = (e) => {
    setSortMethod(e.target.value);
    setCurrentPage(1); // Reset to the first page when sorting changes
  };

  return (
    <div className="collection">
      <div className="container">
        <div className="shop-breadcrumb">
          <div className="shop-breadcrumb__d-flex d-flex align-items-center">
            <h1 className="shop-breadcrumb__title">All Stores</h1>
          </div>
        </div>
        <div className="collection__top-filter clearfix">
          <div className="collection__sort-and-view d-flex align-items-center">
            <div className="products-sort-by d-flex align-items-center">
              <label htmlFor="sort-by">Sort by</label>
              <div className="products-sort-by__select">
                <i className="lnil lnil-chevron-down"></i>
                <select id="sort-by" onChange={handleSortChange} value={sortMethod}>
                  <option value="default">Default</option>
                  <option value="nameAZ">Name (A-Z)</option>
                  <option value="nameZA">Name (Z-A)</option>
                  <option value="rating">Top Rated</option>
                  <option value="productCountAsc">Product Count (Asc.)</option>
                  <option value="productCountDesc">Product Count (Desc.)</option>
                </select>
              </div>
            </div>
          </div>
          <div className="collection__founded-products"><span>{sortedStores.length}</span> Sellers found</div>
        </div>
        <div className="row products-row">
          {currentStores.map((store, index) => (
            <SellerCard key={index} data={store} />
          ))}
        </div>
      </div>
      <ul className="standard-pagination pt-5 mt-5">
        {Array.from({ length: totalPages }, (_, i) => (
          <li key={i + 1}>
            <a href="#" onClick={(e) => {e.preventDefault(); paginate(i + 1)}} className={currentPage === i + 1 ? 'active' : ''}>{i + 1}</a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Stores