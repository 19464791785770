import React from 'react'

function SearchPopup() {
  return (
    <div className="search-popup js-search-popup">
      <div className="search-popup__close"><a href="#" className="js-close-search-popup"><i className="lnil lnil-close"></i></a></div>
      <div className="container">
        <ul className="search-popup__categories">
          <li><a href="#" className="active">All</a></li>
          <li><a href="#">Clothings</a></li>
          <li><a href="#">Shoes</a></li>
          <li><a href="#">Bags</a></li>
          <li><a href="#">Accessories</a></li>
        </ul>
        <form className="search-popup__form"><input type="text" className="search-popup__input" placeholder="Search here..." /></form>
        <div className="search-popups__results">
          <h6 className="search-popup__results-heading">Search results</h6>
          <div className="search-popups__results-products d-flex">
            <div className="product-grid-item">
              <div className="product-grid-item__tag product-grid-item__tag--sold-out">Sold out</div>
              <div className="product-grid-item__wishlist"><a href="#"><i className="lnil lnil-heart"></i></a></div>
              <div className="product-grid-item__image">
                <a href="product.html"><img alt="Image" data-sizes="auto" data-srcset="assets/products/1/6a.jpg 400w,
              assets/products/1/6a.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect" /> <img alt="Image" data-sizes="auto" data-srcset="assets/products/1/6b.jpg 400w,
              assets/products/1/6b.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect hover" /></a>
                <div className="product-grid-item__action d-flex">
                  <div className="product-grid-item__button"><a href="#">Add to cart</a></div>
                  <div className="product-grid-item__button"><a href="#">Quick shop</a></div>
                </div>
              </div>
              <div className="product-grid-item__title"><a href="product.html">Basic T-shitt with slogan</a></div>
              <div className="product-grid-item__price">$32.5</div>
            </div>
            <div className="product-grid-item">
              <div className="product-grid-item__wishlist"><a href="#"><i className="lnil lnil-heart"></i></a></div>
              <div className="product-grid-item__image">
                <a href="product.html"><img alt="Image" data-sizes="auto" data-srcset="assets/products/1/13a.jpg 400w,
              assets/products/1/13a.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect" /> <img alt="Image" data-sizes="auto" data-srcset="assets/products/1/13b.jpg 400w,
              assets/products/1/13b.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect hover" /></a>
                <div className="product-grid-item__action d-flex">
                  <div className="product-grid-item__button"><a href="#">Add to cart</a></div>
                  <div className="product-grid-item__button"><a href="#">Quick shop</a></div>
                </div>
              </div>
              <div className="product-grid-item__title"><a href="product.html">Chrome acetate sunglasses</a></div>
              <div className="product-grid-item__rating"><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><span>3 reviews</span></div>
              <div className="product-grid-item__price">$72.9</div>
            </div>
            <div className="product-grid-item">
              <div className="product-grid-item__tag">10% off</div>
              <div className="product-grid-item__wishlist"><a href="#"><i className="lnil lnil-heart"></i></a></div>
              <div className="product-grid-item__image">
                <a href="product.html"><img alt="Image" data-sizes="auto" data-srcset="assets/products/1/2_1-a.jpg 400w,
              assets/products/1/2_1-a.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect" /> <img alt="Image" data-sizes="auto" data-srcset="assets/products/1/2_1-b.jpg 400w,
              assets/products/1/2_1-b.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect hover" /></a>
                <div className="product-grid-item__action d-flex">
                  <div className="product-grid-item__button"><a href="#">Add to cart</a></div>
                  <div className="product-grid-item__button"><a href="#">Quick shop</a></div>
                </div>
              </div>
              <div className="product-grid-item__title"><a href="product.html">Zipped cotton cardigan</a></div>
              <div className="product-grid-item__price"><span className="price-new">$49.5</span><span className="price-old">$68.5</span></div>
              <div className="product-grid-item__content-tag">2 colours</div>
            </div>
            <div className="product-grid-item">
              <div className="product-grid-item__wishlist"><a href="#"><i className="lnil lnil-heart"></i></a></div>
              <div className="product-grid-item__image">
                <a href="product.html"><img alt="Image" data-sizes="auto" data-srcset="assets/products/1/1b.jpg 400w,
              assets/products/1/1b.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect" /> <img alt="Image" data-sizes="auto" data-srcset="assets/products/1/1c.jpg 400w,
              assets/products/1/1c.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect hover" /></a>
                <div className="product-grid-item__action d-flex">
                  <div className="product-grid-item__button"><a href="#">Add to cart</a></div>
                  <div className="product-grid-item__button"><a href="#">Quick shop</a></div>
                </div>
              </div>
              <div className="product-grid-item__title"><a href="product.html">Crew neck cotton sweatshirt</a></div>
              <div className="product-grid-item__rating"><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><span>3 reviews</span></div>
              <div className="product-grid-item__price">$79.9</div>
            </div>
            <div className="product-grid-item">
              <div className="product-grid-item__wishlist"><a href="#"><i className="lnil lnil-heart"></i></a></div>
              <div className="product-grid-item__image">
                <a href="product.html"><img alt="Image" data-sizes="auto" data-srcset="assets/products/1/3a.jpg 400w,
              assets/products/1/3a.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect" /> <img alt="Image" data-sizes="auto" data-srcset="assets/products/1/3b.jpg 400w,
              assets/products/1/3b.jpg 800w" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" className="lazyload lazy-effect hover" /></a>
                <div className="product-grid-item__action d-flex">
                  <div className="product-grid-item__button"><a href="#">Add to cart</a></div>
                  <div className="product-grid-item__button"><a href="#">Quick shop</a></div>
                </div>
              </div>
              <div className="product-grid-item__title"><a href="product.html">Brown nubuck leather high-top deck shoes</a></div>
              <div className="product-grid-item__rating"><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled active"></i><i className="lnir lnir-star-filled"></i><span>1 review</span></div>
              <div className="product-grid-item__price">$149.5</div>
              <div className="product-grid-item__content-tag">3 colours</div>
            </div>
          </div>
          <div className="search-popup__results-action"><a href="#" className="button">All results (12)</a></div>
        </div>
      </div>
    </div>
  )
}

export default SearchPopup