import Footer from 'components/Footer'
import Header from 'components/Header'
import SearchPopup from 'components/SearchPopup'
import SidebarContact from 'components/SidebarContact'
import React from 'react'
import { Outlet } from 'react-router-dom'

function PageLayout() {
  return (
    <>
      <Header />
      <SidebarContact />
      <Outlet />
      <Footer />
      <SearchPopup />
    </>
  )
}

export default PageLayout