import React, { useEffect, useState } from 'react';
import SingleCartItem from 'components/Cart/SingleCartItem';
import { useDispatch } from 'react-redux';
import { removeFromCartBySellerId, updateCartItemCheckedState } from 'features/cartSlice';
import Swal from 'sweetalert2';

function ShopCart({ details }) {
  const [checkedAll, setCheckedAll] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const allChecked = details.items.every(item => item.checked === true);
    setCheckedAll(allChecked);
  }, [details.items]);

  const handleRemoveItemBySeller = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: `You're about to remove all item by ${details.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeFromCartBySellerId({ sellerId: details.id }));
        Swal.fire({
          title: "Success!",
          text: `Items from ${details.name} has been removed.`,
          icon: "success"
        });
      }
    });
  };

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    setCheckedAll(isChecked);
    details.items.forEach(item => {
      dispatch(updateCartItemCheckedState({ id: item.id, checked: isChecked }));
    });
  };

  return (
    <div className="table-responsive card p-2 mb-2">
      <table className="shopping-cart__table">
        <thead>
          <tr>
            <th>
              <div className="form-check">
                <input 
                  type="checkbox" 
                  className="form-check-input shop-checkbox" 
                  checked={checkedAll} 
                  onChange={handleCheckAll} 
                />
              </div>
            </th>
            <th colSpan="3">{details.name}</th>
            <th>
              <div className="cart-product__delete">
                <a href="#" onClick={(e) => handleRemoveItemBySeller(e)}>
                  <i style={{ fontSize: "large" }} className="lnil lnil-trash"></i>
                </a>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {details.items.map(item => (
            <SingleCartItem key={item.id} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ShopCart;
