const ShopHelper = {
    parseProduct(product, sellersIndex = null) {
        const final_price = Number(((product.discount_percentage) ? product.price - (product.price * (product.discount_percentage/100)) : product.price).toFixed(2));

        let updatedProduct = { ...product, final_price };

        if (sellersIndex && product.shop && product.shop.id) {
            const seller = sellersIndex[product.shop.id];
            if (seller) {
                updatedProduct.shop = {
                    id: seller.id,
                    name: seller.name,
                    description: seller.description,
                    image: seller.image,
                    rating: seller.rating,
                    city: seller.city
                };
            }
        }

        return updatedProduct;
    },
    parseProducts(products, sellersIndex = null) {
        return products.map((e) => this.parseProduct(e, sellersIndex));
    },
    getProductsBySellerId(sellerId, products, sellersIndex = null) {
        return this.parseProducts(products, sellersIndex).filter(product => product.shop.id == sellerId);
    },
    createSellersIndex(sellers) {
        return sellers.reduce((acc, seller) => {
            acc[seller.id] = seller;
            return acc;
        }, {});
    },
    getProductsGroupedBySeller(products, sellers) {
        let toReturn = {};
        
        products = this.parseProducts(products);
        sellers = this.createSellersIndex(sellers);

        products.forEach(product => {
            if (typeof toReturn[product.shop.id] == 'undefined') {
                toReturn[product.shop.id] = {
                    ...sellers[product.shop.id],
                    items: []
                };
            }
            toReturn[product.shop.id].items.push(product);
        });

        return Object.values(toReturn);
    }
}

export default ShopHelper;