import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeFromCart, updateCartItemQty, updateCartItemNote, updateCartItemCheckedState } from 'features/cartSlice';

function SingleCartItem({ item }) {
  const dispatch = useDispatch();

  const [note, setNote] = useState(item.note || '');
  const [qty, setQty] = useState(item.qty || 1);
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [checked, setChecked] = useState(item.checked || false);

  useEffect(() => {
    setChecked(item.checked);
  }, [item]);

  const handleCheckboxChange = (e) => {
    const newCheckedState = e.target.checked;
    setChecked(newCheckedState);
    dispatch(updateCartItemCheckedState({ id: item.id, checked: newCheckedState }));
  };

  const handleRemove = (e) => {
    e.preventDefault();
    dispatch(removeFromCart({ id: item.id }));
  };

  const handleUpdateQty = (newQty) => {
    if (newQty >= 1) {
      setQty(newQty);
      dispatch(updateCartItemQty({ id: item.id, qty: newQty }));
    }
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleNoteSave = () => {
    dispatch(updateCartItemNote({ id: item.id, note }));
    setIsEditingNote(false);
  };

  const handleNoteKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleNoteSave();
    }
  };

  const handleEditNote = (e) => {
    e.preventDefault();
    setIsEditingNote(true);
  };

  return (
    <tr>
      <td>
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input shop-checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
          />
        </div>
      </td>
      <td>
        <div className="shopping-cart__product">
          <div className="cart-product__image">
            <a href="#" style={{paddingBottom: "100%"}}>
              <img alt={item.title} data-sizes="auto" src={`/assets/products/${item.image}`} className="lazyload lazy-effect" />
            </a>
          </div>
          <div className="cart-product__title-and-variant">
            <h3 className="cart-product__title">
              <a href="#">{item.title}</a>
            </h3>
            {!isEditingNote ? (
              <>
                { !!note && (
                  <p className="text-muted small mb-0"><i>Note: {note}</i></p>
                )}
                <div className="cart-product__action pt-0">
                  <a href="#" onClick={handleEditNote}>{ !!note ? "Edit Note" : "Add Note" }</a>
                </div>
              </>
            ) : (
              <textarea
                className="form-control"
                rows="2"
                value={note}
                onChange={handleNoteChange}
                onKeyPress={handleNoteKeyPress}
                onBlur={handleNoteSave}
                maxLength="110"
              />
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="cart-product__price">{ (item.discount_percentage > 0) && (
          <s>${item.price}</s>
        ) } ${item.final_price}</div>
      </td>
      <td>
        <div className="cart-product__quantity-field">
          <div className="quantity-field__minus">
            <a href="#" onClick={(e) => { e.preventDefault(); handleUpdateQty(Math.max(1, qty - 1)) }}>-</a>
          </div>
          <input
            type="text"
            inputMode="numeric"
            value={qty}
            className="quantity-field__input"
            onChange={(e) => handleUpdateQty(Number(e.target.value))}
          />
          <div className="quantity-field__plus">
            <a href="#" onClick={(e) => { e.preventDefault(); handleUpdateQty(qty + 1) }}>+</a>
          </div>
        </div>
      </td>
      <td>
        <div className="cart-product__delete">
          <a href="#" onClick={(e) => handleRemove(e)}>
            <i className="lnil lnil-close"></i>
          </a>
        </div>
      </td>
    </tr>
  );
}

export default SingleCartItem;
