import React from 'react'

function SidebarContact() {
  return (
    <div className="sidebar-contact js-sidebar-contact">
      <div className="sidebar-contact__background js-close-sidebar-contact"></div>
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__close"><a href="#" className="js-close-sidebar-contact"><i className="lnil lnil-close"></i></a></div>
        <div className="sidebar-contact__section">
          <h6 className="sidebar-contact-section__title">Contact</h6>
          <div className="sidebar-contact-section__item d-flex">
            <div className="sidebar-contact-section-item__icon"><i className="lnil lnil-map-marker"></i></div>
            <div className="sidebar-contact-section-item__text">No. Rosecrans Ave, Suite 200 El Segundo, CA 90245. USA</div>
          </div>
          <div className="sidebar-contact-section__item d-flex">
            <div className="sidebar-contact-section-item__icon"><i className="lnil lnil-phone-ring"></i></div>
            <div className="sidebar-contact-section-item__text">Call: +1 (202) 861-5567</div>
          </div>
          <div className="sidebar-contact-section__item d-flex">
            <div className="sidebar-contact-section-item__icon"><i className="lnil lnil-message-edit"></i></div>
            <div className="sidebar-contact-section-item__text">Email: support@demo.com</div>
          </div>
        </div>
        <div className="sidebar-contact__section">
          <h6 className="sidebar-contact-section__title">Connect on Social</h6>
          <ul className="sidebar-contact-section__socials">
            <li><a href="https://twitter.com/" target="_blank"><i className="lnil lnil-twitter"></i></a></li>
            <li><a href="https://facebook.com/" target="_blank"><i className="lnil lnil-facebook"></i></a></li>
            <li><a href="https://instagram.com/" target="_blank"><i className="lnil lnil-Instagram"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SidebarContact