import React from 'react'
import { Link } from 'react-router-dom'

function SellerCard({ data }) {
  return (
    <div className="col-12 col-md-4">
      <div className="card">
        <div className="card-body" style={{ width: "100%" }}>
          <div className="d-flex align-items-center">
            <img
              src={data.image}
              className="me-3"
              style={{ width: '150px', height: 'auto' }}
            />
            <div style={{ width: '100%' }}>
              <h5 className="card-title">{data.name}</h5>
              <p className="card-text">
                {data.city}<br /><i class="lnil lnil-star-fill"></i> {data.rating}
              </p>
            </div>
            <div className="d-grid" style={{ width: "50%" }}>
              <Link to={`/store/${data.id}`} className="btn btn-dark btn-block">View Store</Link>
            </div>
          </div>
          <div className="d-flex align-items-center mt-2">
            { data.items.slice(0, 5).map((item) => (<>
              <div className="d-flex flex-column p-1" style={{width: "20%"}}>
                <img src={`/assets/products/${item.images[0]}`} className="img-fluid" />
                <p>${item.final_price}</p>
              </div>
            </>)) }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SellerCard;
