import { addToCart } from 'features/cartSlice';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function ProductCard({ product }) {
  const dispatch = useDispatch();

  const handleAddToCart = (e) => {
    e.preventDefault();
    dispatch(addToCart({ id: product.id, qty: 1, details: product }));
    
    Swal.fire({
      title: 'Success!',
      text: 'Product added to cart',
      icon: 'success',
      timer: 2000,
      showConfirmButton: false
    });
  };

  const hasDiscount = product.discount_percentage > 0;
  
  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3">
      <div className="product-grid-item">
        {hasDiscount && (
          <div className="product-grid-item__tag">{product.discount_percentage}% off</div>
        )}
        <div className="product-grid-item__image">
          <a href="#" onClick={(e) => e.preventDefault()} style={{paddingBottom: "100%"}}>
            <img
              src={`/assets/products/${product.images[0]}`}
              className="lazyload lazy-effect"
              alt={product.title}
            />
            {product.images[1] && (
              <img
                src={`/assets/products/${product.images[1]}`}
                className="lazyload lazy-effect hover"
                alt={`${product.title} - hover`}
              />
            )}
          </a>
          <div className="product-grid-item__action">
            <div className="product-grid-item__button">
              <a href="#" onClick={handleAddToCart}>Add to cart</a>
            </div>
          </div>
        </div>
        <div className="product-grid-item__title">
          <a href="#" onClick={(e) => e.preventDefault()}>{product.title}</a>
        </div>
        <div className="product-grid-item__price">
          <span className="price-new">${product.final_price.toFixed(2)}</span>
          {hasDiscount && <span className="price-old">${product.price.toFixed(2)}</span>}
        </div>
        <div className="product-grid-item__content-tag">
          <Link to={`/store/${product.shop.id}`}>{product.shop.name}</Link>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;