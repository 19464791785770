import ShopHelper from 'app/ShopHelper';
import ProductCard from 'components/Shop/ProductCard';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

function Shop() {
  useDocumentTitle('All Products');

  const products = useSelector(state => state.products.products);
  const sellers = useSelector(state => state.sellers.sellers);
  const [parsedProducts, setParsedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMethod, setSortMethod] = useState('default');
  const productsPerPage = 8;

  useEffect(() => {
    if (products.length > 0) {
      const sellerIndex = ShopHelper.createSellersIndex(sellers);
      const parsed = ShopHelper.parseProducts(products, sellerIndex);
      setParsedProducts(parsed);
    } else {
      setParsedProducts([]);
    }
  }, [products, sellers]);

  const sortProducts = (products, method) => {
    switch (method) {
      case 'discount':
        return [...products].sort((a, b) => b.discount_percentage - a.discount_percentage);
      case 'priceLowToHigh':
        return [...products].sort((a, b) => a.final_price - b.final_price);
      case 'priceHighToLow':
        return [...products].sort((a, b) => b.final_price - a.final_price);
      default:
        return products;
    }
  };

  const sortedProducts = sortProducts(parsedProducts, sortMethod);

  // For pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(sortedProducts.length / productsPerPage);
  
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSortChange = (e) => {
    setSortMethod(e.target.value);
    setCurrentPage(1); // Reset to the first page when sorting changes
  };

  return (
    <div className="collection">
      <div className="container">
        <div className="shop-breadcrumb">
          <div className="shop-breadcrumb__d-flex d-flex align-items-center">
            <h1 className="shop-breadcrumb__title">All Products</h1>
          </div>
        </div>
        <div className="collection__top-filter clearfix">
          <div className="collection__sort-and-view d-flex align-items-center">
            <div className="products-sort-by d-flex align-items-center">
              <label htmlFor="sort-by">Sort by</label>
              <div className="products-sort-by__select">
                <i className="lnil lnil-chevron-down"></i>
                <select id="sort-by" value={sortMethod} onChange={handleSortChange}>
                  <option value="default">Default</option>
                  <option value="discount">Discount</option>
                  <option value="priceLowToHigh">Price: Low to High</option>
                  <option value="priceHighToLow">Price: High to Low</option>
                </select>
              </div>
            </div>
          </div>
          <div className="collection__founded-products">
            <span>{sortedProducts.length}</span> Products found
          </div>
        </div>
        <div className="row products-row">
          { currentProducts.map(product => (
            <ProductCard key={product.id} product={product} />
          )) }
        </div>
      </div>
      <ul className="standard-pagination pt-5 mt-5">
        {Array.from({ length: totalPages }, (_, i) => (
          <li key={i + 1}>
            <a href="#" onClick={(e) => {e.preventDefault();paginate(i + 1)}} className={currentPage === i + 1 ? 'active' : ''}>{i + 1}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Shop;