import React from 'react'

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-lg-3 copyright__first-column">BLAZ STORE, Kelompok Blazkowizc, UAS Semester 2.</div>
            <div className="col-lg-3 copyright__second-column"></div>
            <div className="col-lg-6 copyright__payment">
              <span>Accepted payments</span><img src="/assets/images/default/payment.png" alt="Payment method" />
              </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer